@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400&display=swap');
* {
  box-sizing: border-box;
}
body {
  background-color: transparent;  
  font-family: 'Poppins', sans-serif;
  display: flex;  
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  margin: 0;
}
.quiz-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 2px rgba(100, 100, 100, 0.1);
  width: 600px;
  max-width: 95vw;
  overflow: hidden;
}
.quiz-header {
  padding: 1rem 4rem 4rem 4rem;
}

.quiz-paginator {
  vertical-align: top;
  margin-top: 20px;
  margin-right: 20px;
  text-align: right;

  font-size: 1.2rem;
}

h2 {
  padding: 1rem;
  text-align: center;
  margin: 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
ul li {
  font-size: 1.2rem;
  margin: 1rem 0;
}
ul li label {
  cursor: pointer;
}
.buttonApply {
  background-color: #8e44ad;
}
.buttonApply:hover {
  background-color: #732d91;
}
.buttonApply:focus {
  outline: none;
  background-color: #5e3370;
}

.buttonSkip {
  background-color: #089d08;
}
.buttonSkip:hover {
  background-color: #36830a;
}
.buttonSkip:focus {
  outline: none;
  background-color: #194b05;
}

.btnOperations {
  color: #fff;
  border: none;
  display: block;
  width: 30%;
  cursor: pointer;
  font-size: 1.1rem;
  font-family: inherit;
  padding: 1.3rem;
  display: inline;
}

.operationButtons {
  display: flex;
  justify-content: space-between;
}

.operationButtons button:first-child {
  border-top-right-radius: 10px;
}
.operationButtons button:last-child {
  border-top-left-radius: 10px;
}

.informationPanel {
  margin: 20px;
}
.informationPanel p {
  margin: 2px;
}
.informationPanel ul li {
  font-size: 1rem;
  list-style: inside;
  margin: 1rem 0 0 1rem;
}
.startButton {
  border-radius: 10px;
}

.informationPanel h2 {
  text-align: center;
}

.examResult p {
  font-size: large;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.startButton {
  background-color: #1aaa21;
}
.startButton:hover {
  background-color: #25d651;
}
.startButton:focus {
  outline: none;
  background-color: #299b45;
}

#btnWrapper {
  width: 100%;
  height: 90px;

  display: flex;
  align-items: center;
  justify-content: center;
}
